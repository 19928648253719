export function scrollToTop() {
  document.getElementById("app-main")?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
}

export function scrollToSpecified(position: number, behavior?: string) {
  document.getElementById("app-main")?.scrollTo({ left: 0, top: position, behavior: behavior || 'instant' });
}

export function scrollIntoView(id: string, position: 'start'|'center'|'end'|'nearest', behavior = "smooth") {
  document.getElementById(id)?.scrollIntoView({ block: position, behavior: behavior });
}
